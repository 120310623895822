import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  DivisionalMarketingFooter,
  MarketingFooter,
  DivisionalFooter,
  CopyrightFooter,
  InformaTechLogo,
} from '../../../../../informakit';
import { HTMLtoJSX } from '../../../../../helpers';

const HubKnect365Footer = (props) => {
  const {
    pageConfig: {
      tenantConfig: { copyright },
    },
  } = props;

  return (
    <DivisionalMarketingFooter>
      <MarketingFooter
        bottomBorder
        eventName="Informa Tech"
        discoverMenu={{
          title: 'Discover more from Informa Tech',
          menuLeft: [
            {
              title: 'Informa Tech Home',
              href: 'https://tech.informa.com/',
            },
            {
              title: 'Event Calendar',
              href: 'https://tech.informa.com/events',
            },
            {
              title: 'TMT Events',
              href: 'https://tmt.knect365.com',
            },
            {
              title: 'Automotive Events',
              href: 'https://automotive.knect365.com/',
            },
          ],
          menuRight: [
            {
              title: 'Our Brands',
              href: 'https://tech.informa.com/brands',
            },
            {
              title: 'London Tech Week',
              href: 'https://londontechweek.com/',
            },
            {
              title: 'Asia Tech x Singapore',
              href: 'https://asiatechxsg.com/',
            },
          ],
        }}
        secondaryMenu={{
          title: 'Working with us',
          menu: [
            {
              title: 'About Us',
              href: 'https://tech.informa.com/about-us',
            },
            {
              title: 'Contact Us',
              href: 'https://tech.informa.com/about-us##informa-footer',
            },
          ],
        }}
        socialMenu={[
          {
            type: 'facebook',
            href: 'https://www.facebook.com/InformaTechHQ/',
            brand: true,
          },
          {
            type: 'twitter',
            href: 'https://twitter.com/informatechhq?lang=en',
            brand: true,
          },
          {
            type: 'youtube',
            href: 'https://www.youtube.com/channel/UCNwhzXVA-5aSYlbvESIEOwA',
            brand: true,
          },
          {
            type: 'linkedin',
            href: 'https://www.linkedin.com/company/informa-tech/',
            brand: true,
          },
          {
            type: 'blog',
            href: 'https://www.glassdoor.co.uk/Reviews/Informa-Tech-Reviews-E2595103.htm',
          },
        ]}
      />
      <DivisionalFooter
        bottomBorder
        showPrefix
        menuItems={[
          {
            title: 'Home',
            href: 'https://tech.informa.com/',
          },
          {
            title: 'Cookies',
            href: 'https://knect365.com/cookie-policy/',
          },
          {
            title: 'CCPA: Do not sell my personal info',
            href: 'https://privacyportal-eu-cdn.onetrust.com/dsarwebform/c1f53e84-9f05-4169-a854-85052b63c50b/5f26b553-52cc-4973-a761-295d5634a6b6.html',
          },
          {
            title: 'Privacy',
            href: 'https://www.informa.com/privacy-policy/',
          },
          {
            title: 'Terms',
            href: 'https://tech.informa.com/terms-and-conditions/',
          },
        ]}
        Logo={
          <InformaTechLogo alt="Brought to you by Informa Tech" showPrefix />
        }
      />
      <CopyrightFooter>{HTMLtoJSX(copyright)}</CopyrightFooter>
    </DivisionalMarketingFooter>
  );
};

HubKnect365Footer.propTypes = {
  pageConfig: PropTypes.shape({
    tenantConfig: PropTypes.shape({
      copyright: PropTypes.string.isRequired,
    }),
  }),
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(HubKnect365Footer);
